import { Prop, Vue } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { AddSiteDTO } from '@/services/program/adminService';

export default class AssignTask extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public hovering: boolean = false;
    public listIndex: string|null = null;
    public openDropStatus:boolean=false;
    public isDropdownVisible:boolean=false;
    public checkAll:boolean = false;
    public sitesData :any = [];
    public selectedTasks: any=[];
    public sitesArray: any = [];
    public isCheckAll: boolean=false;
    public hoverStyleObj:any = {};
    public isCheckAllTasks:boolean=false;
    public dropdownVisibility:boolean = false;

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    @Prop()
    sites!: AddSiteDTO[];

    showDropdown() {
        this.dropdownVisibility = true;
    }

    callHover(title:string, $event:any) {
        this.listIndex = title;
        this.hovering=true;
        const boundBox = $event && $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
          top:(coordY + 35).toString() + "px",
          left:(coordX + 10).toString() + "px"
      }
    }
    callHoverOut() {
        this.listIndex = null;
        this.hovering=false
    }
    public addRemoveSites(site: any) {
        if (this.sitesData.includes(site.id)) {
            const index = this.sitesData.indexOf(site.id);
            if (index > -1) {
                this.sitesData.splice(index, 1);
            }
        }
        else{
            this.sitesData.push(site.id);
        }
    }
    selectAll() {
        this.isCheckAll = !this.isCheckAll;
        this.sitesData = [];
        if(this.isCheckAll){
            for (var key in this.sites) {
                this.addRemoveSites(this.sites[key])
            }
        }
    }
    updateCheckall(){
        if(this.sitesData.length == this.sites.length){
           this.isCheckAll = true;
        }else{
           this.isCheckAll = false;
        }
    }
}
